<template>
  <div class="parent">
    <img style="width: 100%" src="../assets/images/about_bg.png" alt="">
    <div class="about_us">
      <div class="image">
        <img class="image1" src="../assets/images/about_us1.png" alt="">
        <img class="image2" src="../assets/images/about_us2.png" alt="">
      </div>
      <div class="content1">
        <img class="title_image" style="margin-top: 36px" src="../assets/images/about_us.png" alt="">
        <div class="title1">What’s we do?</div>
        <div>{{ desc1 }}</div>
      </div>
    </div>
    <div class="content2">{{ desc2 }}</div>
    <div class="linglong">
      <div class="name">What is LingLong Porcelain？</div>
      <div style="display: flex;flex-flow: row">
        <div class="item1">
          <div class="num">01</div>
          <div class="title">Light</div>
          <div class="desc">Easy to pick up with one hand, very lightweight</div>
        </div>
        <div class="item2">
          <div class="num">02</div>
          <div class="title">Thin</div>
          <div class="desc">Porcelain embryos are very thin</div>
        </div>
        <div class="item3">
          <div class="num">03</div>
          <div class="title">Transparent</div>
          <div class="desc">The surface of the porcelain is smooth and translucent</div>
        </div>
        <div class="item1">
          <div class="num">04</div>
          <div class="title">Elaborate</div>
          <div class="desc">Exquisite porcelain, a non-heritage product</div>
        </div>
      </div>
      <div style="display: flex;">
        <img class="image3" src="../assets/images/linglong1.png" alt="">
        <img class="image3" src="../assets/images/linglong2.png" alt="">
        <img class="image3" src="../assets/images/linglong3.png" alt="">
      </div>
      <div class="desc3">{{ desc3 }}</div>
    </div>
    <div style="padding: 24px;margin-top: 24px">
      <img style="height: 42px" src="../assets/images/history.png" alt="">
    </div>
    <div style="padding: 24px;margin-top: 24px">
      <img style="height: 360px" src="../assets/images/history2.png" alt="">
    </div>
    <div style="padding: 0 24px 24px 24px;">
      <img style="height: 42px" src="../assets/images/company_activity.png" alt="">
    </div>
    <div style="height: auto;overflow: hidden;width: 100%" ref="scrollContainer">
      <div class="banner">
        <img v-for="(image, index) in bannerImageList" :key="index"
             style="display: block;width: 100%;object-fit: contain" :src="image.path" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'aboutUsPage',
  data() {
    return {
      desc1: 'We focuses on AlOT and sound&light deep fusion of product research, development and industry application system integration services, the company has a professional R & D team, design team, production team, creative team, after-sales service team.etc., is committed to providing a more comfortable sound and light products, so that sound and light science and technology + oriental aesthetics of the products into the thousands of households.',
      desc2: 'Our company has a professional team, combining Jingdezhen Linglong Porcelain non-heritage craftsmanship, modern LED lighting technology and modern sound and light science and technology, and launching a series of products, such as Celestial Porcelain Music Lamp and Linglong PorcelainMusic Lamp, which not only have oriental aesthetics, but also emit pleasant music.\n\n' +
          'In the future, we will continue to develop products around "sound and light and health", and layout of smart home, smart audio, intelligent lighting solutions and other application scenarios, to serve the community and our customers.',
      desc3: 'Patterns are designed on very thin embryos of clay and delicate holes are carved into the clay. A special transparent glaze is then applied to the finished clay blanks, After high temperature firing, the skeletonised holes are beautifully transparent but impervious to water.',
      bannerImageList: [],
      scrollInterval: null,
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      axios.get('v1/about/get').then((res) => {
        if (res.data.code === '1') {
          let data = res.data.data
          this.desc1 = data.description
          this.desc2 = data.text
          if (data.file_ids && data.file_ids !== '') {
            this.bannerImageList = JSON.parse(data.file_ids)
          } else {
            this.bannerImageList = []
          }
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(res => {
        console.log(res)
      });
    },
  },
}
</script>

<style lang="less" scoped>

.banner {
  display: flex;
  gap: 12px;
}

.parent {
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: auto;
  word-break: break-word;
}

.about_us {
  display: flex;
  margin: 24px;
  width: 900px;

  .image {
    width: 400px;
    position: relative;
    height: 310px;
  }

  .image1 {
    width: 300px;
    height: 200px;
  }

  .image2 {
    width: 300px;
    height: 200px;
    position: absolute;
    bottom: 0;
    right: 0
  }

  .content1 {
    white-space: pre-wrap;
    text-align: justify;
    margin-left: 36px;
    width: 450px;
    font-size: 14px;
    color: #333333;
    font-style: italic;
    max-width: 450px;
  }
}

.content2 {
  width: 900px;
  margin-bottom: 36px;
  white-space: pre-wrap;
  text-align: justify;
  font-size: 14px;
  color: #333333;
  font-style: italic;
}

.linglong {
  width: 100%;
  background: url('../assets/images/aboutus2.png');
  background-size: cover;
  box-sizing: border-box;
  padding: 36px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .name {
    font-weight: 400;
    margin-bottom: 36px;
    font-size: 30px;
    color: #FFFFFF;
    font-style: italic;
  }

  .item1 {
    padding: 16px;
    width: 240px;
    border: 1px solid #666666;
  }

  .item2 {
    padding: 16px;
    width: 240px;
    border-top: 1px solid #666666;
    border-bottom: 1px solid #666666;
    border-right: 1px solid #666666;
  }

  .item3 {
    padding: 16px;
    width: 240px;
    border-top: 1px solid #666666;
    border-bottom: 1px solid #666666;
  }

  .num {
    color: #CB1F20;
    font-weight: bold;
    font-size: 16px;
    font-style: italic;
  }

  .title {
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    margin: 18px 0;
    font-style: italic;
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    font-style: italic;
  }
}

.image3 {
  width: 250px;
  margin: 36px;
  height: 300px;
}

.history_image {
  width: 60%;
}

.title_image {
  height: 28px;
}

.title1 {
  font-size: 24px;
  color: #333333;
  font-style: italic;
  margin: 24px 0;
}

.desc3 {
  font-weight: 400;
  width: 1096px;
  font-size: 16px;
  white-space: pre-wrap;
  text-align: justify;
  color: rgba(255, 255, 255, 0.7);
  font-style: italic;
}

</style>